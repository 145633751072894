<template>
  <div class="black-background">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <ul>
        <li
          v-for="quote in quotes"
          :key="quote.id"
          :class="{ quote: true, visible: quote.visible }"
          :data-index="quote.id"
        >
          <p class="quote-text">{{ quote.quote }}</p>
          - <strong>{{ quote.author }}</strong> ({{ quote.category }})
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL // in production this is 'https://denisjevtic.com/api'

export default {
  data() {
    return {
      quotes: [],
      loading: false,
    }
  },
  created() {
    this.fetchQuotesList()
  },
  mounted() {
    // this.observeQuotes(); // Call the method to observe quotes
  },
  methods: {
    async fetchQuotesList() {
      this.loading = true
      try {
        const response = await axios.get('/get-quotes') // Use axios for the request
        console.log('The response was:', response)
        console.log('The response was:', 'okkkkkkkkkkkkkKK')
        this.quotes = response.data
      } catch (error) {
        console.error('There was an error fetching the quotes:', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style>
html,
body {
  margin: 0; /* Removes default margin */
  padding: 0; /* Removes default padding */
  height: 100%; /* Ensures they take the full height */
}

.black-background {
  background-color: black;
  color: white;
  padding: 20px;
  text-align: center; /* Center align the content */
  min-height: 100vh; /* This ensures that the div takes at least the full height of the viewport */
  text-align: center;
}

.white-text {
  color: white;
}

.quote {
  margin: 20px 0; /* Increase margin */
  padding: 40px; /* Add padding */
  font-size: 2em; /* Increase font size */
  min-height: 40vh; /* Each quote takes at least 40% of the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* Remove transition and opacity styles */
}

.quote-text {
  font-family: Georgia, serif; /* Specify the font-family */
  font-style: italic;
  font-size: 3em; /* Significantly larger font size for the quote */
  line-height: 1.3; /* Adjust line height for better readability */
}

strong {
  font-size: 1.5em; /* Larger font size for author */
}
</style>
