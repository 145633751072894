import { defineStore } from 'pinia'
export const useAuthStore = defineStore('auth', {
  state: () => ({
    idToken: null,
  }),
  actions: {
    setIdToken(token) {
      this.idToken = token
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'auth',
        storage: localStorage, // or sessionStorage
      },
    ],
  },
})
