import { createRouter, createWebHistory } from 'vue-router'
import QuotesList from '../components/QuotesList.vue' // Your new Quotes component
import HomePage from '../components/Home.vue'
import LogIn from '../components/LogIn.vue'
import SubmitQuote from '../components/SubmitQuote.vue'
import { useAuthStore } from '@/stores/auth'
import axios from 'axios'
const routes = [
  { path: '/', component: HomePage },
  { path: '/quotes', component: QuotesList }, // New route for the Quotes page
  { path: '/log-in', component: LogIn }, // New route for the Quotes page
  {
    path: '/submit-quote',
    name: 'SubmitQuote',
    component: SubmitQuote,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.idToken) {
        // User is authenticated, allow access to the route
        const config = {
          headers: {
            Authorization: `Bearer ${authStore.idToken}`, // Include the idToken in the Authorization header
          },
        }
        axios
          .get('/validate-token', config)
          .then((response) => {
            // Handle the response if needed
            console.log(response)
            next()
          })
          .catch((error) => {
            console.error('Error fetching data:', error)
            next('/log-in') // Redirect to an log-in page
          })
      } else {
        // User is not authenticated, redirect to log-in
        next('/log-in')
      }
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
