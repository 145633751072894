<template>
  <div>
    <h1>Submit a Quote</h1>
    <form @submit.prevent="submitQuote">
      <div>
        <label for="quote">Quote:</label>
        <input id="quote" v-model="quoteInfo.quote" required />
      </div>
      <div>
        <label for="category">Category:</label>
        <input id="category" v-model="quoteInfo.category" required />
      </div>
      <div>
        <label for="author">Author:</label>
        <input id="author" v-model="quoteInfo.author" />
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
export default {
  data() {
    return {
      quoteInfo: {
        quote: '',
        category: '',
        author: '',
      },
    }
  },
  methods: {
    async submitQuote() {
      try {
        const authStore = useAuthStore()
        const token = authStore.idToken
        const response = await axios.put('/quotes', this.quoteInfo, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        // Handle response, maybe redirect to another page or show a success message
        console.log('Quote submitted:', response.data)
      } catch (error) {
        console.error('Submission failed:', error)
      }
    },
  },
}
</script>
