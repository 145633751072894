<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
  <div class="#535a5e pt-5 p-st" style="background-color: #818589">
    <!-- Your content here -->

    <div
      class="container"
      data-aos="fade"
      data-aos-once="true"
      data-aos-duration="1000"
      style="padding-bottom: 20px; background-color: #818589"
    >
      <div class="row align-items-center">
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 text-center">
          <img :src="picture" class="img-fluid" />
        </div>
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 pt-5">
          <span class="home-title text-light">Denis Jevtic</span>
          <p class="text-light" v-html="description.data"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="#535a5e pt-5 p-st" style="background-color: #d3d3d3">
    <div class="container">
      <div class="row">
        <!-- Experience on the left (col-md-6) -->
        <div class="col-xl-12 col-md-12 col-sm-12">
          <TimeLine :data="experience" />
        </div>
        <!-- <div class="col-xl-6 col-md-6 col-sm-12">
        <TimeLine :data="education" />
      </div> -->
      </div>
    </div>
  </div>
  <div class="#535a5e pt-5 p-st" style="background-color: #818589">
    <div class="container">
      <div class="row">
        <!-- Experience on the left (col-md-6) -->
        <div class="col-xl-12 col-md-12 col-sm-12">
          <TimeLine :data="education" />
        </div>
      </div>
    </div>
  </div>
  <div class="#535a5e pt-5 p-st" style="background-color: #d3d3d3">
    <div class="container">
      <SkillsComponent id="skills" />
    </div>
  </div>

  <footer class="footer" style="background-color: #818589">
    <div class="container">
      <p class="text-center text-muted">
        © 2024 Denis Jevtic. All Rights Reserved.
        <button class="btn btn-outline-secondary mx-2" @click="open('linkedin')">
          <i class="fab fa-linkedin"></i>
        </button>
      </p>
    </div>
  </footer>
</template>

<script>
import TimeLine from './helpers/Timeline'
import SkillsComponent from './helpers/SkillsComponent'
import 'bootstrap/dist/css/bootstrap.min.css'

import info from '../info'

export default {
  name: 'HomePage',
  components: {
    TimeLine,
    SkillsComponent,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  methods: {
    open(link) {
      switch (link) {
        case 'linkedin':
          console.log(this.linkedin)
          window.open(this.linkedin, '_blank')
          break
        case 'github':
          window.open(this.github, '_blank')
          break
        case 'angellist':
          window.open(this.angellist, '_blank')
          break
        case 'resume':
          window.open(this.resume, '_blank')
          break
      }
    },
  },
  data() {
    return {
      picture: info.flat_picture,
      description: {
        title: 'description',
        data: info.description,
      },
      experience: {
        title: 'Experience',
        data: info.experience,
      },
      education: {
        title: 'Education',
        data: info.education,
      },
      linkedin: info.links.linkedin,
      github: info.links.github,
      angellist: info.links.angellist,
      resume: info.links.resume,
    }
  },
}
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}
.home-title {
  font-size: 28px;
  font-weight: 500;
}
.light-gray-background {
  background-color: #f2f2f2; /* Light gray color; adjust as needed */
}

.footer {
  background-color: #818589;
  color: #fff;
  padding: 20px 0; /* Adjust padding as needed */
}

.footer p {
  margin: 0; /* Remove default margin */
  font-size: 14px; /* Adjust font size as needed */
}

/* Center the copyright text */
.footer p.text-center {
  text-align: center;
}
</style>
