<template>
  <v-app>
    <v-app-bar dark>
      <v-toolbar-title>DenisJevtic.com</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="nav-item">
        <router-link to="/"> Home </router-link>
      </div>
      <div class="nav-item">
        <router-link to="/quotes"> Quotes </router-link>
      </div>

      <div class="nav-item">
        <router-link to="/submit-quote"> Quotes Submission </router-link>
      </div>

      <div class="nav-item">
        <router-link to="/log-in"> LogIn </router-link>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style>
.nav-item {
  margin-right: 10px; /* Adjust as needed */
}
</style>

<script>
export default {
  name: 'App',
  components: {},
}
</script>
<style>
/* Add global styles here */
</style>
