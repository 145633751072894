<template>
  <div class="login-container">
    <form @submit.prevent="onLogin">
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="username" id="username" v-model="credentials.email" required />
      </div>

      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="credentials.password" required />
      </div>

      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL // in production this is 'https://denisjevtic.com/api'

export default {
  name: 'LogIn',

  data() {
    return {
      credentials: {
        email: '',
        password: '',
      },
    }
  },

  methods: {
    async onLogin() {
      try {
        // The URL endpoint for the login
        const loginUrl = 'sign-in' // Replace with your actual login URL

        // Sending a POST request with credentials
        const response = await axios.post(loginUrl, this.credentials)

        // Handle the response here (e.g., storing tokens, navigating to another page)
        console.log('Login successful:', response.data)
        const token = response.data.idToken
        console.log('token is: ', token)
        // Save the token in the store
        const authStore = useAuthStore()
        authStore.setIdToken(token)
        this.$router.push({ name: 'SubmitQuote' })
      } catch (error) {
        // Handle errors (e.g., incorrect credentials, network issues)
        console.error('Login failed:', error)
      }
    },
  },
}
</script>

<style>
.login-container {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
