import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // Import router if you're using Vue Router
import vuetify from './plugins/vuetify' // Ensure you have this vuetify plugin file set up
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persist'
// Create the Vue application
const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
// Use Vuetify and Vue Router
app.use(vuetify)
app.use(router)
app.use(pinia)

// Mount the app to the DOM
app.mount('#app')
