let info = {
  flat_picture: require('./assets/denis.jpg'),
  name: 'Denis Jevtic',
  config: {
    use_cookies: true,
    navbar: {
      blur: false,
    },
  },
  description:
    "I'm a software engineer specialising in Python. Passionate about my craft, I enjoy delivering projects that make an impact to peoples lives. If we can automate the mundane, we can free up time for the interesting! Outside of the world of engineering, I spend my time training and competing in Brazillian Jiu Jitsu where I hold a purple belt. <br> As someone who enjoys a good old memorable quote, please go to the quotes page, where I continue to compile some of my favourite.",
  links: {
    linkedin: 'https://uk.linkedin.com/in/denis-jevtic-2a19bb157',
  },
  education: [
    {
      name: 'Royal Holloway, University Of London',
      place: 'Egham, Surrey, United Kingdom',
      date: 'September 2014 - June 2018',
      degree: 'MSci Physics',
      gpa: '2:1',
      description:
        'Major Project: Computational Modelling of the Structure and Properties of Gas Hydrates. Research Review: ’Advancements in Quantum Computation’',
    },
  ],
  experience: [
    {
      name: 'Gigaclear',
      place: 'Abingdon, United Kingdom',
      date: 'November 2021 - Present',
      position: 'Software Engineer',
      description:
        'Developing and architecting web applications (REST APIs, databases, UI) deployed as microservices. Technical lead for the One Touch Switch Project, which allows customers to take a new telecoms service (broadband and/or voice) with a new provider without contacting their current provider, messages are exchanged between telecoms providers via a centralised hub developed by TOTSCo (The One Touch Switch Company). We have been one of the first telecoms companies to start integration testing with another major telecoms provider, taking leadership in setting the industry standard, this has involved regular sync up and triage calls to diagnose issues with both the hub and our own systems. Maintaining and delivering tools for designing, building and maintaining optical fibre networks. Collaborated with cross-functional teams to successfully integrate technical solutions into systems such as Salesforce and NetAdmin. Hiring and onboarding new software engineers, fostering a supportive and knowledge-sharing environment within the team. Founded and led the Junior Dev Forum, an initiative aimed at assisting new developers in overcoming challenges and acquiring essential skills. Working in a Scrum framework',
      skills: [
        'Python',
        'PostgreSQL',
        'Aiohttp',
        'Flask',
        'Docker',
        'Kubernetes',
        'VueJS',
        'TypeScript',
        'GitLab',
        'Git',
        'Gitlab CI',
      ],
    },
    {
      name: 'CoinFLEX',
      place: 'London, United Kingdom',
      date: 'October 2019 - November 2021',
      position: 'Risk Analyst',
      description:
        "Developed real-time dashboards using Python's Dash package, querying Apache Ignite databases to track and present frequently updating client account information, improving risk management and client behavior tracking. Collaborated with high-frequency traders to facilitate smooth integration with our APIs. Conducted extensive testing of individual components, focusing on the Risk Engine, collaborating closely with the lead developer to identify and resolve bugs. Managing risk during market volatility. Played a role in the development of a new exchange, providing guidance on the business aspect, drafting business specifications, and overseeing projects from start to finish",
      skills: ['Python', 'PostgreSQL'],
    },
    {
      name: 'CloudTrade',
      place: 'London, United Kingdom',
      date: 'January 2019 - October 2019',
      position: 'Analyst',
      description: '',
      skills: [],
    },
    {
      name: 'Algolabs',
      place: 'Bracknell, Surrey, United Kingdom',
      date: 'July 2017 - September 2017',
      position: 'Summer Analyst',
      description:
        'Analysed (Time-Series) data to uncover relationships in FX markets around economic news releases. Built dashboards in Tableau regarding client trading behaviour and presented this to the managing director and senior people in the parent company',
      skills: ['Tableau', 'Excel'],
    },
  ],
  skills: [
    {
      title: 'Languages',
      info: ['Python', 'Javascript', 'TypeScript', 'VueJS', 'Serbian', 'Croatian', 'Bosnian'],
      icon: 'fa fa-code',
    },
    {
      title: 'Frameworks',
      info: ['AioHTTP', 'Flask', 'FastAPI'],
      icon: 'fa fa-cubes',
    },
  ],
  portfolio_design: [{}],
  portfolio: [],
}

export default info
